import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { useNavigate } from "react-router-dom";
import paramsService from "../../services/params.service";

const StepFour = (props) => {
  const [showNickNames, setShowNickNames] = useState();
  const [leftSleeveStatus, setLeftSleeveStatus] = useState();
  const [rightSleeveStatus, setRightSleeveStatus] = useState();
  const [isValidStep, setIsValidStep] = useState(false);
  const [listOfName, setListOfNames] = useState([]);
  const [importNamesStatus, setImportNamesStatus] = useState(false);

  const dispatch = useDispatch();

  // const tempId = 1;
  const cart = useSelector((state) => state.cart);
  const basket = cart.basket;
  const students = basket.students;

  let history = useNavigate();

  const nickInputDisabled = {
    width: "242px",
    borderRight: "1px solid #e3e3e3",
  };

  const setActivePage = () => {
    dispatch(
      cartActions.setActiveStep({
        step: "FOUR",
      })
    );
  };

  const onLeftSleeveNickNamesChangeHandler = (event) => {
    const status = event.target.value === "true" ? true : false;
    setLeftSleeveStatus(status);
    setShowNickNames(status || rightSleeveStatus);
    dispatch(
      cartActions.setSleeveNickNames({
        side: "left",
        status: status,
      })
    );
  };

  const onRightSleeveNickNamesChangeHandler = (event) => {
    const status = event.target.value === "true" ? true : false;
    setRightSleeveStatus(status);
    setShowNickNames(leftSleeveStatus || status);
    dispatch(
      cartActions.setSleeveNickNames({
        side: "right",
        status: status,
      })
    );
  };

  const onNickNameChangeHandler = (event) => {
    dispatch(
      cartActions.setSchoolNickNamesData({
        id: event.target.id,
        value: event.target.value,
      })
    );
  };

  const onLeftSleeveTextChangeHandler = (event) => {
    dispatch(
      cartActions.setSleeveText({
        side: "left",
        text: event.target.value,
      })
    );
  };

  const onRightSleeveTextChangeHandler = (event) => {
    dispatch(
      cartActions.setSleeveText({
        side: "right",
        text: event.target.value,
      })
    );
  };

  const previousStepNotComplete = () => {
    const isIncomplete = !cart.stepThreeValid
    setIsValidStep(!isIncomplete)
    return isIncomplete
  }



  const onImportNamesChangeHandler = (event) => {
    var lines = event.target.value.split('\n');
    for (var i = 0; i < lines.length; i++) {
      console.log(lines[i])
      dispatch(
        cartActions.setSchoolNickNamesData({
          id: "nickName#" + (i),
          value: lines[i],
        })
      );
    }

    setListOfNames(event.target.value)



  }

  const toggleImportNamesHandler = (event) => {
    setImportNamesStatus(event.target.checked);

  }

  const ownTextStyle = { width: "93%", height: "150px" };

  const checkVersion = async () => {
    const currentVersion = await paramsService.getVersion()

    if (cart?.minimumVersion === undefined || cart?.minimumVersion !== currentVersion) {
      dispatch(cartActions.resetStore({}));
      history('/')
    }
  }
  
  useEffect(() => {
    if (previousStepNotComplete()) {
      history('/stepThree')
      return
    }
    setActivePage()
    setLeftSleeveStatus(basket.sleeves?.leftSleeveNickNames)
    setRightSleeveStatus(basket.sleeves?.rightSleeveNickNames)
    setShowNickNames(basket.sleeves?.leftSleeveNickNames || basket.sleeves?.rightSleeveNickNames)
    checkVersion()
  });

  return (
    isValidStep && <React.Fragment>
      <div className="simplyForm marginTop20">
        <h2 className="strip n4">Levý rukáv</h2>

        <fieldset>
          <label>Text pro levý rukáv:</label>
          <input
            type="text"
            name="left_sleeve_text"
            id="left_sleeve_text"
            value={basket.sleeves.leftSleeveText}
            className="text big form_input"
            disabled={leftSleeveStatus === true}
            onChange={onLeftSleeveTextChangeHandler}
          />
          <div className="radioInputs clearfix">
            <span>Přezdivky</span>
            <label>
              <input type="radio" name="left_sleeve_nickname" className="nickname_radio form_input" value="true" checked={leftSleeveStatus === true} onChange={onLeftSleeveNickNamesChangeHandler} />
              Ano
            </label>
            <label>
              <input type="radio" name="left_sleeve_nickname" className="form_input" value="false" checked={leftSleeveStatus !== true} onChange={onLeftSleeveNickNamesChangeHandler} />
              Ne
            </label>
          </div>
        </fieldset>

        <h2 className="strip n4">Pravý rukáv</h2>

        <fieldset>
          <label>Text pro pravý rukáv:</label>
          <input
            type="text"
            name="right_sleeve_text"
            id="right_sleeve_text"
            value={basket.sleeves.rightSleeveText}
            className="text big form_input"
            disabled={rightSleeveStatus === true}
            onChange={onRightSleeveTextChangeHandler}
          />
          <div className="radioInputs clearfix">
            <span>Přezdivky</span>
            <label>
              <input type="radio" name="right_sleeve_nickname" className="nickname_radio form_input" value="true" checked={rightSleeveStatus === true} onChange={onRightSleeveNickNamesChangeHandler} />
              Ano
            </label>
            <label>
              <input type="radio" name="right_sleeve_nickname" className="form_input" value="false" checked={rightSleeveStatus !== true} onChange={onRightSleeveNickNamesChangeHandler} />
              Ne
            </label>
          </div>
        </fieldset>

        {showNickNames === true && (
          <div id="nicknames_div">
            <h2 className="strip n4">Přezdívky</h2>
            <strong><center><h2 className="n4">Přezdívky si přiřadíte k jednotlivým velikostem textilu v kroku 05. objednávky.</h2></center></strong>
            <fieldset className="names" id="nicknames_list">
              <div className="rows">
                <div className="clearfix">
                  <label className="name">Jméno Příjmení</label>
                  <label className="nick">Přezdívky</label>
                </div>
                {[...Array(Math.max(students.names.length, 30))].map((elementInArray, tempId) => (
                  <div className="row clearfix" key={`nickNameLineId#${tempId}`}>
                    <div className="num">{tempId + 1}</div>
                    <div className="nameInput nickInputDisabled" style={nickInputDisabled}>
                      <input type="text" className="nickInputDisabled" readOnly="readonly" name={`studentName#${tempId}`} value={students.names[tempId]?.name} />
                    </div>
                    <div className="nickInput">
                      <input type="text" name={`nickName#${tempId}`} value={students.nicks[tempId]?.name} onChange={onNickNameChangeHandler} id={`nickName#${tempId}`} />
                    </div>
                  </div>
                ))}
              </div>
              <div className="row inactive clearfix">
                <div className="num bold">+</div>
                <div className="nameInput">Přidat další pole</div>
                <div className="nickInput">&nbsp;</div>
                <div className="remove">&nbsp;</div>
              </div>
            </fieldset>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default StepFour;
